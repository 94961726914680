import { createContext, useContext } from 'react';
import { STORAGE } from 'storage/localStorageEnum';
import { getLocalItem, setLocalItem } from 'storage/localStorage';

const setUserToLocalStorage = (data) => {
  setLocalItem(STORAGE.USER_DATA, data.user_details);
  setLocalItem(STORAGE.TOKEN, data.token);
};

const AuthContext = createContext({
  user: getLocalItem(STORAGE.USER_DATA),
  setUser: () => null,
  checkPermission: () => null,
  checkOptionalPermission: () => null,
  isAuthenticate: () => false,
  isMainContractor: () => false,
  isSubContractor: () => false,
  isSuperAdmin: () => false,
  isSubUser: () => false,
  hasNotFrontRole: () => false,
  checkDashboardActionPermission: () => false,
  isMainUser: () => false,
  updateLocalUserDetails: () => null,
});

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthContext, setUserToLocalStorage };
