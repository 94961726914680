import styles from './401.module.css';
import { NotFound } from 'assets/icon-components';
import React, { memo } from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuthUser } from 'hooks/useAuthUser';
import Button from 'components/core/button';

const Page401 = () => {
  const { handleLogout } = useAuthUser();
  return (
    <div className={styles.container401}>
      <NotFound className={styles.icon} />
      <span className={styles.text}>Unauthorized !!</span>
      <Button className={styles.link} onClick={handleLogout}>
        <MdOutlineLogout fontSize={25} />
        Please logout and login again!!
      </Button>
    </div>
  );
};

export default memo(Page401);
