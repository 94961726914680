import { DocumentCopy, Eye, TrashOutline } from 'assets/icon-components';
import styles from '../table.module.css';
import React from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
const TableActions = ({
  _id,
  onView,
  onEdit,
  onDelete,
  hideViewButton = false,
  hideDeleteButton = false,
  hideEditButton = false,
  showCopyButton = false,
  rowSpan,
}) => (
  <td key={`table-body-action-${_id}`} className={styles.fixedActionsColumn} rowSpan={rowSpan}>
    <div className={styles.buttonsBox}>
      {/* Hide Copy Button */}
      {showCopyButton && (
        <button type="button" onClick={() => onEdit?.(_id)}>
          <DocumentCopy width="1.00144rem" height="1rem" />
        </button>
      )}

      {/* Edit Button with tooltip */}
      {!hideEditButton && (
        <button
          type="button"
          onClick={() => onEdit?.(_id)}
          style={{
            backgroundColor: 'transparent',
            border: '0.00675rem solid #2F98FA',
            borderRadius: '8px',
            padding: '0.50rem',
            cursor: 'pointer',
          }}
        >
          <MdEdit width="0.97563rem" height="1rem" color="white" />
        </button>
      )}

      {/* Delete Button with tooltip */}
      {!hideDeleteButton && (
        <button
          type="button"
          onClick={() => onDelete?.(_id)}
          style={{
            backgroundColor: 'transparent',
            border: '0.00675rem solid #FF5A65',
            borderRadius: '8px',
            padding: '0.50rem',
            cursor: 'pointer',
          }}
        >
          <TrashOutline width="0.97563rem" height="1rem" />
        </button>
      )}

      {/* View Button with tooltip */}
      {!hideViewButton && (
        <button
          type="button"
          onClick={() => onView?.(_id)}
          style={{
            backgroundColor: 'transparent',
            border: '0.00675rem solid green',
            borderRadius: '8px',
            padding: '0.50rem',
            cursor: 'pointer',
          }}
        >
          <Eye width="0.97563rem" height="1rem" />
        </button>
      )}
    </div>
  </td>
);

TableActions.propTypes = {
  _id: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hideViewButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  showCopyButton: PropTypes.bool,
  rowSpan: PropTypes.number,
};

export default TableActions;
