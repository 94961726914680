import React from 'react';
import PropTypes from 'prop-types';

const CheckboxGroup = ({ options, selectedValues, onChange }) => {
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      onChange([...selectedValues, value]);
    } else {
      onChange(selectedValues.filter((selectedValue) => selectedValue !== value));
    }
  };

  // Group options by their group property
  const groupedOptions = options.reduce((acc, option) => {
    const group = acc.find((g) => g.group === option.group);
    if (group) {
      group.options.push(option);
    } else {
      acc.push({ group: option.group, options: [option] });
    }
    return acc;
  }, []);

  return (
    <div className="checkbox-group flex flex-col gap-4">
      {groupedOptions.map((group) => (
        <div key={group.group} className="checkbox-group-item mb-4">
          <div className="group-label font-bold text-white mb-2 font-poppins text-[18px]">
            {group.group}
          </div>
          <div className="flex flex-wrap gap-0">
            {group.options.map((option) => {
              const checkboxId = `checkbox-${option.value}`;
              return (
                <div key={option.value} className="checkbox-item flex items-center mx-3 mb-2">
                  <input
                    type="checkbox"
                    id={checkboxId}
                    value={option.value}
                    checked={selectedValues.includes(option.value)}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                  />
                  <label htmlFor={checkboxId} className="ml-2 text-white font-poppins">
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxGroup;
