import React from 'react';

const SvgNotFound = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 182 174"
    {...props}
  >
    <path
      fill="#E5E7EB"
      d="M90.21 173.846c44.625 0 80.801-36.176 80.801-80.8 0-44.626-36.176-80.802-80.8-80.802-44.626 0-80.802 36.176-80.802 80.801s36.176 80.801 80.801 80.801Z"
    />
    <path
      fill="#6B7280"
      d="M133.801 31.045h-89.51c-5.58 0-10.102 4.523-10.102 10.102h109.714c0-5.58-4.522-10.102-10.102-10.102Z"
    />
    <path
      fill="#F3F4F6"
      d="M34.188 40.871h109.715v83.561a14.007 14.007 0 0 1-14.008 14.008H48.196a14.009 14.009 0 0 1-14.008-14.008v-83.56Z"
    />
    <path
      fill="#D1D5DB"
      d="M125.044 51.428h-71.96a2.712 2.712 0 0 0-2.714 2.71 2.712 2.712 0 0 0 2.714 2.71h71.96a2.712 2.712 0 0 0 2.714-2.71 2.712 2.712 0 0 0-2.714-2.71ZM125.044 74.459h-71.96a2.712 2.712 0 0 0-2.714 2.71 2.712 2.712 0 0 0 2.714 2.711h71.96a2.712 2.712 0 0 0 2.714-2.71 2.712 2.712 0 0 0-2.714-2.711ZM125.044 97.492h-71.96a2.712 2.712 0 1 0 0 5.42h71.96a2.712 2.712 0 0 0 2.714-2.709 2.712 2.712 0 0 0-2.714-2.71ZM125.044 120.523h-71.96a2.712 2.712 0 0 0-2.714 2.711 2.712 2.712 0 0 0 2.714 2.711h71.96a2.713 2.713 0 0 0 2.714-2.711 2.713 2.713 0 0 0-2.714-2.711Z"
    />
    <g filter="url(#not_found_svg__a)">
      <path
        fill="#F3F4F6"
        d="M52.596 92.553H14.278a5.574 5.574 0 0 0-5.574 5.574v54.769a5.574 5.574 0 0 0 5.574 5.574h38.318a5.574 5.574 0 0 0 5.574-5.574v-54.77a5.574 5.574 0 0 0-5.574-5.573Z"
      />
    </g>
    <g filter="url(#not_found_svg__b)">
      <path
        fill="#F3F4F6"
        d="M161.676 7h-42.324a3.571 3.571 0 0 0-3.571 3.571v19.893a3.571 3.571 0 0 0 3.571 3.571h42.324a3.571 3.571 0 0 0 3.571-3.57V10.57A3.571 3.571 0 0 0 161.676 7Z"
      />
    </g>
    <path
      fill="#9CA3AF"
      d="M48.32 119.951H18.555a1.815 1.815 0 0 0-1.815 1.815v.006c0 1.002.813 1.815 1.815 1.815h29.767a1.815 1.815 0 0 0 1.814-1.815v-.006a1.815 1.815 0 0 0-1.814-1.815ZM48.337 145.531h-29.51c-.994 0-1.8.787-1.8 1.759v.005c0 .971.806 1.758 1.8 1.758h29.51c.994 0 1.8-.787 1.8-1.758v-.005c0-.972-.806-1.759-1.8-1.759ZM48.337 132.146h-29.51c-.994 0-1.8.788-1.8 1.759v.005c0 .972.806 1.759 1.8 1.759h29.51c.994 0 1.8-.787 1.8-1.759v-.005c0-.971-.806-1.759-1.8-1.759ZM29.437 101.008H20.74a3.489 3.489 0 0 0-3.489 3.489v2.414a3.489 3.489 0 0 0 3.489 3.488h8.698a3.489 3.489 0 0 0 3.49-3.488v-2.414a3.489 3.489 0 0 0-3.49-3.489Z"
    />
    <path
      fill="#E5E7EB"
      d="M126.227 24.615a4.105 4.105 0 1 0 0-8.21 4.105 4.105 0 0 0 0 8.21ZM140.898 24.615a4.106 4.106 0 1 0 0-8.21 4.106 4.106 0 0 0 0 8.21ZM155.57 24.615a4.106 4.106 0 1 0 0-8.21 4.106 4.106 0 0 0 0 8.21Z"
    />
    <path
      fill="#1F2937"
      d="m144.438 122.541-10.398 11.463 29.308 26.584 10.398-11.463-29.308-26.584Z"
    />
    <path
      fill="#1F2937"
      fillRule="evenodd"
      d="M155.427 103.568c0 23.982-19.441 43.422-43.422 43.422-23.981 0-43.422-19.44-43.422-43.422 0-23.98 19.44-43.421 43.422-43.421 23.981 0 43.422 19.44 43.422 43.421Zm-6.661 0c0 20.303-16.458 36.762-36.761 36.762-20.303 0-36.762-16.459-36.762-36.762s16.459-36.761 36.762-36.761 36.761 16.458 36.761 36.761Z"
      clipRule="evenodd"
    />
    <g filter="url(#not_found_svg__c)">
      <path
        fill="#F9FAFB"
        d="M112.005 140.33c20.303 0 36.761-16.459 36.761-36.762s-16.458-36.761-36.761-36.761c-20.303 0-36.762 16.458-36.762 36.761 0 20.303 16.459 36.762 36.762 36.762Z"
      />
    </g>
    <path
      fill="#EF4444"
      d="m120.839 103.568 10.513-10.537a5.506 5.506 0 0 0-.109-7.68 5.508 5.508 0 0 0-7.679-.108L113.027 95.78l-10.513-10.537a5.507 5.507 0 0 0-6.077-1.32 5.51 5.51 0 0 0-1.712 9.108l10.532 10.537-10.532 10.537a5.513 5.513 0 0 0-1.32 6.077 5.514 5.514 0 0 0 5.182 3.436 5.507 5.507 0 0 0 3.927-1.725l10.537-10.537 10.536 10.537a5.508 5.508 0 0 0 9.149-3.972 5.51 5.51 0 0 0-1.384-3.816l-10.513-10.537Z"
    />
    <g filter="url(#not_found_svg__d)">
      <ellipse cx={113.221} cy={123.874} fill="#FEE2E2" rx={25.843} ry={3.818} />
    </g>
    <path
      fill="#EF4444"
      d="M83.353 149.232c.365-1.511 2.513-1.511 2.878 0l1.227 5.083c.13.54.552.961 1.091 1.091l5.083 1.227c1.51.365 1.51 2.513 0 2.878l-5.083 1.227a1.48 1.48 0 0 0-1.091 1.091l-1.227 5.084c-.365 1.51-2.513 1.51-2.878 0l-1.227-5.084a1.48 1.48 0 0 0-1.091-1.091l-5.084-1.227c-1.51-.365-1.51-2.513 0-2.878l5.084-1.227c.54-.13.96-.551 1.091-1.091l1.227-5.083Z"
    />
    <path
      fill="#FCA5A5"
      d="M24.884 13.978c.365-1.51 2.513-1.51 2.878 0l1.227 5.083c.13.54.552.961 1.091 1.091l5.084 1.228c1.51.364 1.51 2.512 0 2.877l-5.084 1.227a1.48 1.48 0 0 0-1.091 1.092l-1.227 5.083c-.365 1.51-2.513 1.51-2.878 0l-1.227-5.083a1.48 1.48 0 0 0-1.091-1.092l-5.083-1.227c-1.51-.365-1.51-2.513 0-2.877l5.083-1.227a1.48 1.48 0 0 0 1.091-1.092l1.227-5.083Z"
    />
    <path
      fill="#FCA5A5"
      fillRule="evenodd"
      d="M86.989 14.801a2.467 2.467 0 1 0 0-4.933 2.467 2.467 0 0 0 0 4.933Zm0 1.85a4.317 4.317 0 1 0 0-8.633 4.317 4.317 0 0 0 0 8.633Z"
      clipRule="evenodd"
    />
    <path
      fill="#FCA5A5"
      d="M163.693 82.726a1.234 1.234 0 0 1 1.529-1.493l6.17 1.734a1.234 1.234 0 0 1 .528 2.07l-4.587 4.477a1.233 1.233 0 0 1-2.056-.578l-1.584-6.21Z"
    />
    <path
      fill="#EF4444"
      d="M19.069 72.135a1.233 1.233 0 0 1 1.49 1.531l-1.742 6.168a1.233 1.233 0 0 1-2.07.525l-4.471-4.592a1.233 1.233 0 0 1 .58-2.056l6.213-1.576Z"
    />
    <path
      fill="#DC2626"
      d="M136.712 157.374a1.234 1.234 0 0 1 1.491 1.531l-1.742 6.167a1.233 1.233 0 0 1-2.071.525l-4.47-4.592a1.233 1.233 0 0 1 .58-2.056l6.212-1.575Z"
    />
    <path
      fill="#EF4444"
      fillRule="evenodd"
      d="M169.409 50.729a2.466 2.466 0 1 0 0-4.933 2.466 2.466 0 0 0 0 4.933Zm0 1.85a4.317 4.317 0 1 0 0-8.634 4.317 4.317 0 0 0 0 8.634Z"
      clipRule="evenodd"
    />
    <path
      fill="#1F2937"
      d="m163.347 160.59 10.397-11.463 1.567 1.42a7.74 7.74 0 0 1 .532 10.927 7.738 7.738 0 0 1-10.926.532l-1.566-1.421-.004.005Z"
    />
    <defs>
      <filter
        id="not_found_svg__a"
        width={70.599}
        height={87.051}
        x={0.251}
        y={86.917}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2.113} dy={4.931} />
        <feGaussianBlur stdDeviation={5.283} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_515_67277" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_515_67277" result="shape" />
      </filter>
      <filter
        id="not_found_svg__b"
        width={67.781}
        height={45.351}
        x={110.85}
        y={0.66}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={4.227} dy={2.818} />
        <feGaussianBlur stdDeviation={4.579} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_515_67277" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_515_67277" result="shape" />
      </filter>
      <filter
        id="not_found_svg__c"
        width={97.017}
        height={97.017}
        x={63.496}
        y={55.06}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={5.873} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_515_67277" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_515_67277" result="shape" />
      </filter>
      <filter
        id="not_found_svg__d"
        width={81.052}
        height={37.002}
        x={72.694}
        y={105.373}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_515_67277" stdDeviation={7.342} />
      </filter>
    </defs>
  </svg>
);
export default SvgNotFound;
