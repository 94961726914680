import React from 'react';
import GuestRoute from 'routes/GuestRoute';
import PublicRoute from 'routes/PublicRoute';
import ROUTES_ENUMS from 'routes/url.enum';
import { Route, Routes } from 'react-router-dom';
import PermissionsMiddlewareRoute from 'routes/PermissionsMiddlewareRoute';
import { PERMISSIONS } from 'constants/config.constants';
import MasterPage from 'components/layout/MasterPage';
import * as UserDashboard from 'pages/user-dashboard';
import * as UserAuth from 'pages/user-auth';
import * as PublicPages from 'pages/public-pages';
import { Page401, Page404 } from 'components/core';
import RoleManagementLayoutLayout from 'pages/user-dashboard/components/role-management/RoleManagementLayout';
import SettingLayout from 'pages/user-dashboard/components/settings-components/SettingLayout';

const Routings = () => {
  return (
    <Routes>
      <Route element={<GuestRoute />}>
        <Route path={ROUTES_ENUMS.AUTH.INDEX} element={<UserAuth.Login />} />
        <Route path={ROUTES_ENUMS.AUTH.SIGN_UP} element={<UserAuth.Registration />} />
        <Route path={ROUTES_ENUMS.AUTH.FORGOT_PASSWORD} element={<UserAuth.ForgotPassword />} />
        <Route path={ROUTES_ENUMS.AUTH.RESET_PASSWORD} element={<UserAuth.ResetPassword />} />
        <Route path={ROUTES_ENUMS.AUTH.VERIFICATION} element={<UserAuth.Verification />} />
      </Route>
      <Route element={<MasterPage />}>
        <Route
          path={ROUTES_ENUMS.INVITATION_LIST}
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.DASHBOARD]}>
              <UserDashboard.InvitationList />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.DASHBOARD}
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.DASHBOARD]}>
              <UserDashboard.Dashboard />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.PROJECTS}
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.PROJECT.VIEW_PROJECTS]}>
              <UserDashboard.Project />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.ARCHIVED_PROJECTS}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VIEW_ARCHIVE_PROJECTS]}
            >
              <UserDashboard.ArchivedProject />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.ARCHIVED_PROJECT_DETAILS}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VIEW_SINGLE_PROJECT]}
            >
              <UserDashboard.ViewProject />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.VIEW_PROJECT}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VIEW_SINGLE_PROJECT]}
            >
              <UserDashboard.ViewProject />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.SUB_CONTRACTOR_VARIATION}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VIEW_SINGLE_PROJECT]}
            >
              <UserDashboard.SubContractorVariationList />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.CREATE_PROJECT}
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.PROJECT.CREATE_PROJECT]}>
              <UserDashboard.CreateProject />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.ACCOUNT}
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.ACCOUNT]}>
              <UserDashboard.Account />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.VARIATION_DETAILS}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VARIATIONS.VIEW_SINGLE_VARIATION]}
            >
              <UserDashboard.VariationDetail />
            </PermissionsMiddlewareRoute>
          }
        />
        <Route
          path={ROUTES_ENUMS.RFI_DETAILS}
          element={
            <PermissionsMiddlewareRoute
              requiredPermissions={[PERMISSIONS.PROJECT.VARIATIONS.VIEW_RFI]}
            >
              <UserDashboard.RfiDetail />
            </PermissionsMiddlewareRoute>
          }
        />

        {/* Setting Layout  */}
        <Route
          element={
            <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.SETTINGS]}>
              <SettingLayout />
            </PermissionsMiddlewareRoute>
          }
        >
          <Route path={ROUTES_ENUMS.SETTING.INDEX} element={<UserDashboard.SettingProfile />} />

          <Route
            path={ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.INDEX}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.LIST]}>
                <UserDashboard.MainContractorList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.CREATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.CREATE]}>
                <UserDashboard.AddMainContractor />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.UPDATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.UPDATE]}>
                <UserDashboard.EditMainContractor />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.INDEX}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.LIST]}>
                <UserDashboard.SubContractorList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.CREATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.CREATE]}>
                <UserDashboard.AddSubContractor />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.UPDATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.UPDATE]}>
                <UserDashboard.EditSubContractor />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.USERS.INDEX}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.LIST]}>
                <UserDashboard.SettingUsersList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.USERS.CREATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.CREATE]}>
                <UserDashboard.SettingAddUser />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.USERS.UPDATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.UPDATE]}>
                <UserDashboard.SettingEditUser />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.APP_USERS.INDEX}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.LIST]}>
                <UserDashboard.SettingAppUsersList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.APP_USERS.CREATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.CREATE]}>
                <UserDashboard.SettingAddAppUser />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.SETTING.APP_USERS.UPDATE}
            element={
              <PermissionsMiddlewareRoute requiredPermissions={[PERMISSIONS.USER.UPDATE]}>
                <UserDashboard.SettingEditAppUser />
              </PermissionsMiddlewareRoute>
            }
          />
        </Route>

        {/* Role Management Layout  */}
        <Route element={<RoleManagementLayoutLayout />}>
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.INDEX}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.ROLE_MANAGEMENT.ROLE.LIST]}
              >
                <UserDashboard.RoleList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.CREATE}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.ROLE_MANAGEMENT.ROLE.CREATE]}
              >
                <UserDashboard.AddRole />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.UPDATE}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.ROLE_MANAGEMENT.ROLE.UPDATE]}
              >
                <UserDashboard.EditRole />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.INDEX}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.SUB_ADMIN_MANAGEMENT.LIST]}
              >
                <UserDashboard.SubAdminList />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.CREATE}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.SUB_ADMIN_MANAGEMENT.CREATE]}
              >
                <UserDashboard.AddSubAdmin />
              </PermissionsMiddlewareRoute>
            }
          />
          <Route
            path={ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.UPDATE}
            element={
              <PermissionsMiddlewareRoute
                requiredPermissions={[PERMISSIONS.SUB_ADMIN_MANAGEMENT.UPDATE]}
              >
                <UserDashboard.EditSubAdmin />
              </PermissionsMiddlewareRoute>
            }
          />
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path={ROUTES_ENUMS.PUBLIC_VARIATION_DETAILS}
          element={<PublicPages.ClientPublicVariation />}
        />
        <Route path={ROUTES_ENUMS.DELETE_ACCOUNT} element={<PublicPages.DeleteAccount />} />
        <Route path={ROUTES_ENUMS.PRIVACY_POLICY} element={<PublicPages.PrivacyPolicy />} />
        <Route
          path={ROUTES_ENUMS.TERMS_AND_CONDITION}
          element={<PublicPages.TermsAndCondition />}
        />
      </Route>
      <Route path="/401" element={<Page401 />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Routings;
