import React from 'react';

const SvgEye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="green"
      fillRule="evenodd"
      d="M8.004 2.668c2.758.002 5.234 1.935 6.624 5.17a.495.495 0 0 1 0 .395c-1.39 3.237-3.866 5.17-6.624 5.172H8c-2.757-.002-5.233-1.935-6.624-5.172a.501.501 0 0 1 0-.394C2.767 4.603 5.244 2.67 8 2.668h.004Zm-.002 1c-2.29 0-4.38 1.63-5.62 4.368 1.24 2.74 3.33 4.368 5.62 4.369 2.292-.001 4.38-1.63 5.62-4.369-1.24-2.739-3.328-4.367-5.62-4.368Zm0 1.76a2.611 2.611 0 0 1 2.608 2.609 2.61 2.61 0 0 1-2.608 2.606 2.61 2.61 0 0 1-2.607-2.607A2.61 2.61 0 0 1 8.002 5.43Zm0 1A1.609 1.609 0 1 0 9.61 8.037a1.61 1.61 0 0 0-1.608-1.608Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
