import React from 'react';
const SvgDocumentCopy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <g stroke="#4338CA" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.125}>
      <path d="M12.75 10.05v2.25c0 3-1.2 4.2-4.2 4.2H5.7c-3 0-4.2-1.2-4.2-4.2V9.45c0-3 1.2-4.2 4.2-4.2h2.25" />
      <path d="M12.75 10.05h-2.4c-1.8 0-2.4-.6-2.4-2.4v-2.4l4.8 4.8ZM8.7 1.5h3M5.25 3.75A2.247 2.247 0 0 1 7.5 1.5h1.965M16.5 6v4.643a2.11 2.11 0 0 1-2.107 2.107M16.5 6h-2.25C12.562 6 12 5.437 12 3.75V1.5L16.5 6Z" />
    </g>
  </svg>
);
export default SvgDocumentCopy;
