const TABLE = {
  PER_PAGE_OPTIONS: [
    { value: 10, label: '10 / Page' },
    { value: 20, label: '20 / Page' },
    { value: 50, label: '50 / Page' },
    { value: 100, label: '100 / Page' },
  ],
  LIST_CUSTOM_STYLE: {
    width: 'inherit',
  },
  LIST_DATA_STYLE: {
    fontSize: '0.9rem',
  },
  LIST_COMMON_COLUMN: [
    { key: 'status', title: 'Status' },
    { key: 'createdAt', title: 'Created On' },
  ],
};

const FORM = {
  TEXT_FIELD_SUFFIX_CUSTOM_STYLE: {
    height: 'inherit',
  },
  DROPDOWN_MENU_ITEM_STYLE: {
    display: 'grid',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    gridTemplateColumns: 'auto auto auto auto',
  },
  INITIAL_COMMON_FIELDS: {
    status: true,
  },
};

const STATUS_LABEL_OPTIONS = [
  { status: true, label: 'True', type: 'Success' },
  { status: false, label: 'False', type: 'Danger' },
];

const DEFAULT_ROLES = {
  SUPER_ADMIN: 'super_admin',
  MAIN_CONTRACTOR: 'main_contractor',
  SUB_CONTRACTOR: 'sub_contractor',
  QS: 'qs',
  PM: 'pm',
  SM: 'sm',
  OTHER: 'other',
};

const DEFAULT_FRONT_ROLES = {
  QS: 'qs',
  PM: 'pm',
  SM: 'sm',
  OTHER: 'other',
};

const PERMISSIONS = {
  DASHBOARD: 'view_dashboard',
  SETTINGS: 'manage_settings',
  ACCOUNT: 'view_account',
  USER: {
    DELETE: 'delete_user',
    UPDATE: 'update_user',
    VIEW: 'view_user',
    CREATE: 'create_user',
    LIST: 'view_users',
  },
  PROJECT: {
    CREATE_PROJECT: 'create_project',
    VIEW_ARCHIVE_PROJECTS: 'view_archive_projects',
    MAKE_ARCHIVE_PROJECT: 'make_archive_project',
    VIEW_PROJECTS: 'view_projects',
    VIEW_SINGLE_PROJECT: 'view_project',
    UPDATE_PROJECT: 'update_project',
    DELETE_PROJECT: 'delete_project',
    VARIATIONS: {
      CREATE_VARIATION: 'create_variation',
      VIEW_ALL_VARIATION: 'view_variations',
      VIEW_SINGLE_VARIATION: 'view_variation',
      EXPORT_VARIATION: 'export_variations',
      UPDATE_VARIATION: 'update_variation',
      DELETE_VARIATION: 'delete_variation',
      VIEW_RFI: 'view_rfi',
    },
  },
  ROLE_MANAGEMENT: {
    ROLE: {
      DELETE: 'delete_role',
      UPDATE: 'update_role',
      VIEW: 'view_role',
      CREATE: 'create_role',
      LIST: 'view_roles',
    },
  },
  SUB_ADMIN_MANAGEMENT: {
    DELETE: 'delete_sub_admin_user',
    UPDATE: 'update_sub_admin_user',
    VIEW: 'view_sub_admin_user',
    CREATE: 'create_sub_admin_user',
    LIST: 'view_sub_admin_users',
  },
};

export { TABLE, FORM, STATUS_LABEL_OPTIONS, PERMISSIONS, DEFAULT_ROLES, DEFAULT_FRONT_ROLES };
