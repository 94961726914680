import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'context/auth/AuthContext';
import ROUTES_ENUMS from 'routes/url.enum';

const PermissionsMiddlewareRoute = ({ children, requiredPermissions }) => {
  const { isAuthenticate, checkPermission } = useAuth();
  const isAccessTokenValid = isAuthenticate();

  if (!isAccessTokenValid) {
    return <Navigate to={ROUTES_ENUMS.AUTH.INDEX} />;
  }

  const hasPermission = checkPermission(requiredPermissions);

  if (!hasPermission) {
    return <Navigate to={ROUTES_ENUMS[401]} />;
  }

  return children;
};

PermissionsMiddlewareRoute.propTypes = {
  children: PropTypes.element.isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PermissionsMiddlewareRoute;
