import { useNavigate } from 'react-router-dom';
import ROUTES_ENUMS from 'routes/url.enum';
import { useAuth } from 'context/auth/AuthContext';
import { clearLocalStorage } from 'storage/localStorage';

export const useAuthUser = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLogout = () => {
    clearLocalStorage();
    setUser({
      userDet: null,
      token: null,
    });
    navigate(ROUTES_ENUMS.AUTH.INDEX);
  };

  return { handleLogout };
};
