import axios from 'axios';
import ROUTES_ENUMS from 'routes/url.enum';
import { clearLocalStorage, getLocalItem } from 'storage/localStorage';
import { STORAGE } from 'storage/localStorageEnum';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://your-api-base-url.com';

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (req) => {
    const LOGIN_USER_ACCESS_TOKEN = getLocalItem(STORAGE.TOKEN);
    if (LOGIN_USER_ACCESS_TOKEN) {
      req.headers.Authorization = `Bearer ${LOGIN_USER_ACCESS_TOKEN}`;
    }
    return req;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle specific error status codes
      switch (error.response.status) {
        case 401:
          // Clear local storage and redirect to login page
          clearLocalStorage();
          window.location.href = ROUTES_ENUMS.AUTH.INDEX; // Redirect to login page
          break;
        case 404:
          // Handle not found
          break;
        case 500:
          // Handle server error
          break;
        default:
        // Handle other errors
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
