import { useAuth } from 'context/auth/AuthContext';
import ROUTES_ENUMS from 'routes/url.enum';

const useResetError = ({ resetErrorBoundary }) => {
  const { isAuthenticate } = useAuth();
  const isAuthenticated = isAuthenticate();
  const resetError = () => {
    resetErrorBoundary();
    if (!isAuthenticated) {
      window.location.href = ROUTES_ENUMS.AUTH.INDEX;
    } else {
      window.location.href = ROUTES_ENUMS.DASHBOARD;
    }
  };
  return { resetError };
};

export default useResetError;
