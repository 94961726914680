import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 right-0 flex items-center justify-end py-3 px-3 bg-gray-50">
      <img src="/images/one-line-sinq.png" alt="Sinq Constructions" width="132" height="27" />
    </footer>
  );
};

export default Footer;
