import React, { Suspense } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { ComponentLoader } from 'components/core';
import ROUTES_ENUMS from 'routes/url.enum';
import { useAuth } from 'context/auth/AuthContext';
import { PERMISSIONS } from 'constants/config.constants';
import { FaUserGroup } from 'react-icons/fa6';
import useRoleManagement from './hooks/useRoleManagement';

const RoleManagementLayoutLayout = () => {
  const { pathname, getNavLinkClass, isRoleRoute, isSubAdminRoute } = useRoleManagement();
  const { checkPermission } = useAuth();
  const isCanShowRoleList = checkPermission([PERMISSIONS.ROLE_MANAGEMENT.ROLE.LIST]);
  const isCanShowSubAdminList = checkPermission([PERMISSIONS.SUB_ADMIN_MANAGEMENT.LIST]);

  return (
    <div className="border border-border !bg-navy rounded-[12px] p-6 pt-8 mt-[30px] mb-[26px]">
      <div className="w-full mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex justify-center gap-2">
            <FaUserGroup className="text-[22px]" />
            <h3 className="capitalize font-Monasans text-[22px]">Role Management</h3>
          </div>
        </div>
        <div className="flex gap-[34px] mt-[18px]">
          <div className="max-w-[250px] min-w-[250px] w-full">
            <div className="settings flex flex-col gap-2 w-full">
              {isCanShowRoleList && (
                <NavLink
                  to={ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.INDEX}
                  className={getNavLinkClass(isRoleRoute(pathname))}
                >
                  Roles
                </NavLink>
              )}
              {isCanShowSubAdminList && (
                <NavLink
                  to={ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.INDEX}
                  className={getNavLinkClass(isSubAdminRoute(pathname))}
                >
                  Sub Admins
                </NavLink>
              )}
            </div>
          </div>
          <div className="w-full">
            <Suspense fallback={<ComponentLoader className="h-[65vh]" />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoleManagementLayoutLayout;
