import { useLocation, useParams } from 'react-router-dom';
import ROUTES_ENUMS from 'routes/url.enum';
import { GenerateRoute } from 'utils/CommonFun';

export default function useSetting() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const currentTab = pathname.split('/').pop();

  const isSettingRoute = () => {
    return pathname === ROUTES_ENUMS.SETTING.INDEX;
  };

  const isMainContractorRoute = () => {
    const editMainContractor = GenerateRoute(ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.UPDATE, {
      ':id': id,
    });
    const routes = [
      ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.INDEX,
      ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.CREATE,
      editMainContractor,
    ];

    return routes.some((route) => pathname === route);
  };

  const isSubContactorRoute = () => {
    const editSubContractor = GenerateRoute(ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.UPDATE, {
      ':id': id,
    });
    const routes = [
      ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.INDEX,
      ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.CREATE,
      editSubContractor,
    ];

    return routes.some((route) => pathname === route);
  };

  const isUsersRoute = () => {
    const editMainContractor = GenerateRoute(ROUTES_ENUMS.SETTING.USERS.UPDATE, {
      ':id': id,
    });
    const routes = [
      ROUTES_ENUMS.SETTING.USERS.INDEX,
      ROUTES_ENUMS.SETTING.USERS.CREATE,
      editMainContractor,
    ];

    return routes.some((route) => pathname === route);
  };

  const isAppUsersRoute = () => {
    const editMainContractor = GenerateRoute(ROUTES_ENUMS.SETTING.APP_USERS.UPDATE, {
      ':id': id,
    });
    const routes = [
      ROUTES_ENUMS.SETTING.APP_USERS.INDEX,
      ROUTES_ENUMS.SETTING.APP_USERS.CREATE,
      editMainContractor,
    ];

    return routes.some((route) => pathname === route);
  };

  const getNavLinkClass = (isActive) => {
    return `hover:text-white text-[14px] hover:bg-primary border border-border rounded-lg px-3 py-2 flex ${isActive ? 'bg-primary' : 'bg-navy'}`;
  };

  return {
    currentTab,
    pathname,
    getNavLinkClass,
    isSettingRoute,
    isMainContractorRoute,
    isSubContactorRoute,
    isUsersRoute,
    isAppUsersRoute,
  };
}
