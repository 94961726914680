import { lazy } from 'react';

const ArchivedProject = lazy(() => import('pages/user-dashboard/components/archived-project-page'));
const VariationDetail = lazy(() => import('pages/user-dashboard/components/variation-detail'));
const Dashboard = lazy(() => import('pages/user-dashboard/components/dashboard-components'));
const Project = lazy(() => import('pages/user-dashboard/components/project-components'));
const ViewProject = lazy(() => import('pages/user-dashboard/components/view-project'));
const CreateProject = lazy(() => import('pages/user-dashboard/components/create-project'));
const RfiDetail = lazy(() => import('pages/user-dashboard/components/rfi-details'));
const Account = lazy(() => import('pages/user-dashboard/components/account-page'));
const InvitationList = lazy(() => import('pages/user-dashboard/components/invitation-list'));

// main contractor
const MainContractorList = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/main-contractor/components/List'
    )
);
const AddMainContractor = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/main-contractor/components/Add'
    )
);
const EditMainContractor = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/main-contractor/components/Edit'
    )
);

// sub contractor
const SubContractorList = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/sub-contractor/components/List'
    )
);
const AddSubContractor = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/sub-contractor/components/Add'
    )
);
const EditSubContractor = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/sub-contractor/components/Edit'
    )
);
// users pages
const SettingUsersList = lazy(
  () =>
    import('pages/user-dashboard/components/settings-components/components/users/components/List')
);
// app-users pages
const SettingAppUsersList = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/app-users/components/List'
    )
);

const SettingProfile = lazy(
  () => import('pages/user-dashboard/components/settings-components/components/profile/Profile')
);
const SettingAddUser = lazy(
  () =>
    import('pages/user-dashboard/components/settings-components/components/users/components/Add')
);
const SettingAddAppUser = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/app-users/components/Add'
    )
);
const SettingEditUser = lazy(
  () =>
    import('pages/user-dashboard/components/settings-components/components/users/components/Edit')
);
const SettingEditAppUser = lazy(
  () =>
    import(
      'pages/user-dashboard/components/settings-components/components/app-users/components/Edit'
    )
);

// RoleManagement Pages
const RoleList = lazy(
  () =>
    import('pages/user-dashboard/components/role-management/components/roles/components/list/List')
);
const AddRole = lazy(
  () =>
    import('pages/user-dashboard/components/role-management/components/roles/components/add/Add')
);
const EditRole = lazy(
  () =>
    import('pages/user-dashboard/components/role-management/components/roles/components/edit/Edit')
);
const SubAdminList = lazy(
  () =>
    import(
      'pages/user-dashboard/components/role-management/components/sub-admin/components/list/List'
    )
);
const AddSubAdmin = lazy(
  () =>
    import(
      'pages/user-dashboard/components/role-management/components/sub-admin/components/add/Add'
    )
);
const EditSubAdmin = lazy(
  () =>
    import(
      'pages/user-dashboard/components/role-management/components/sub-admin/components/edit/Edit'
    )
);

const SubContractorVariationList = lazy(
  () =>
    import(
      'pages/user-dashboard/components/view-project/components/sub-contractor-variations/SubContractorVariationList'
    )
);

export {
  ArchivedProject,
  VariationDetail,
  Dashboard,
  Project,
  ViewProject,
  CreateProject,
  Account,
  RfiDetail,
  SettingProfile,
  SettingUsersList,
  SettingAppUsersList,
  SettingAddUser,
  SettingAddAppUser,
  SettingEditUser,
  SettingEditAppUser,
  RoleList,
  AddRole,
  EditRole,
  SubAdminList,
  AddSubAdmin,
  EditSubAdmin,
  MainContractorList,
  AddMainContractor,
  EditMainContractor,
  SubContractorList,
  AddSubContractor,
  EditSubContractor,
  SubContractorVariationList,
  InvitationList,
};
