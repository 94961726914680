import { useState } from 'react';

const UseDeleteModal = (onDelete) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [deletedId, setDeletedId] = useState(null);

  const openDialog = (id) => {
    setIsOpenDialog(true);
    setDeletedId(id);
  };

  const closeDialog = () => {
    setIsOpenDialog(false);
    setDeletedId(null);
  };

  const handleConfirm = () => {
    onDelete(deletedId);
    closeDialog();
  };
  return {
    isOpenDialog,
    deletedId,
    openDialog,
    closeDialog,
    handleConfirm,
  };
};

export default UseDeleteModal;
