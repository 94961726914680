import { Button, ComponentLoader } from 'components/core';
import { useAuth } from 'context/auth/AuthContext';
import { useAuthUser } from 'hooks/useAuthUser';
import React, { Suspense } from 'react';
import { FiPower, FiSettings } from 'react-icons/fi';
import { NavLink, Outlet } from 'react-router-dom';
import ROUTES_ENUMS from 'routes/url.enum';
import useSetting from './hooks/useSetting';

const SettingLayout = () => {
  const {
    getNavLinkClass,
    isSettingRoute,
    isMainContractorRoute,
    isSubContactorRoute,
    isUsersRoute,
    isAppUsersRoute,
  } = useSetting();
  const { isSubUser, hasNotFrontRole } = useAuth();
  const { handleLogout } = useAuthUser();
  const isSubUserFlag = isSubUser();
  const isAdminUser = hasNotFrontRole();

  return (
    <div className="border border-border !bg-navy rounded-[12px] p-4 sm:p-6 mt-4 sm:mt-[30px] mb-4 sm:mb-[26px] max-h-[calc(100vh-130px)] overflow-y-auto">
      <div className="w-full mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
          <div className="flex items-center gap-2 mb-4 sm:mb-0">
            <FiSettings className="text-xl sm:text-[22px]" />
            <h3 className="capitalize font-Monasans text-xl sm:text-[22px]">Settings</h3>
          </div>
          <Button
            classes="border-primary text-primary flex items-center gap-2 p-2 w-full sm:w-auto"
            style="bordered"
            onClick={handleLogout}
          >
            <FiPower className="text-[15px] font-bold" />
            Log out
          </Button>
        </div>

        {/* Main Content */}
        <div className="flex flex-col xl:flex-row gap-6 mt-4 sm:mt-[18px]">
          {/* Navigation Sidebar */}
          <div className="w-full xl:w-64 xl:min-w-64 lg:w-full lg:max-w-md">
            <div className="flex flex-col flex-wrap gap-2 settings sm:flex-row xl:flex-col">
              <NavLink
                to={ROUTES_ENUMS.SETTING.INDEX}
                className={getNavLinkClass(isSettingRoute())}
              >
                Profile
              </NavLink>
              {isSubUserFlag !== true && (
                <React.Fragment>
                  {isAdminUser && (
                    <React.Fragment>
                      <NavLink
                        to={ROUTES_ENUMS.SETTING.MAIN_CONTRACTOR.INDEX}
                        className={getNavLinkClass(isMainContractorRoute())}
                      >
                        Main Contactor
                      </NavLink>
                      <NavLink
                        to={ROUTES_ENUMS.SETTING.SUB_CONTRACTOR.INDEX}
                        className={getNavLinkClass(isSubContactorRoute())}
                      >
                        Sub Contactor
                      </NavLink>
                    </React.Fragment>
                  )}
                  <NavLink
                    to={{
                      pathname: ROUTES_ENUMS.SETTING.USERS.INDEX,
                      search: '',
                    }}
                    className={getNavLinkClass(isUsersRoute())}
                  >
                    Web Users
                  </NavLink>
                  <NavLink
                    to={{
                      pathname: ROUTES_ENUMS.SETTING.APP_USERS.INDEX,
                      search: '',
                    }}
                    className={getNavLinkClass(isAppUsersRoute())}
                  >
                    App Users
                  </NavLink>
                </React.Fragment>
              )}
            </div>
          </div>

          {/* Main Content Area */}
          <div className="w-full xl:flex-1">
            <Suspense fallback={<ComponentLoader className="h-[50vh] sm:h-[65vh]" />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingLayout;
