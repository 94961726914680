import styles from '../table.module.css';
import React from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import usePaginationRange from 'components/core/table/hooks/usePaginationRange';
import { tablePerPageOption } from '../table.constants';
import PropTypes from 'prop-types';
import SelectBox from 'components/select-box/SelectBox';

const TableFooter = (props) => {
  const {
    rowsCount,
    nextButtonOnClick,
    backButtonOnClick,
    currentPage,
    pageSize,
    isCustomPageRange = false,
    customPageRange,
    handlePageRange,
  } = props;
  const { paginationRange } = usePaginationRange({
    rowsCount,
    currentPage,
    pageSize,
  });

  return (
    <div className={styles.footer}>
      <div className={styles.contentBox}>
        <SelectBox
          field="pageLimit"
          hideErrorSpace
          value={
            isCustomPageRange
              ? customPageRange?.find((ctr) => ctr.value === pageSize)
              : tablePerPageOption?.find((ctr) => ctr.value === pageSize)
          }
          options={isCustomPageRange ? customPageRange : tablePerPageOption}
          controllerStyles={{
            minWidth: '100px',
            height: '50px',
          }}
          menuListClass={{
            overflow: 'hidden',
          }}
          isSearchable={false}
          onChange={handlePageRange}
        />
        <span className={styles.rangeLable}>{paginationRange.rangeLabel}</span>
        <span className={styles.arrows}>
          <button
            type="button"
            disabled={paginationRange.isDisabledPreButton}
            onClick={backButtonOnClick}
            className={styles.paginationButton}
          >
            <MdOutlineKeyboardArrowLeft size={20} color="#fff" />
          </button>
          <button
            type="button"
            disabled={paginationRange.isDisabledNextButton}
            onClick={nextButtonOnClick}
            className={styles.paginationButton}
          >
            <MdOutlineKeyboardArrowRight size={20} color="#fff" />
          </button>
        </span>
      </div>
    </div>
  );
};

TableFooter.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  nextButtonOnClick: PropTypes.func.isRequired,
  backButtonOnClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  isCustomPageRange: PropTypes.bool,
  customPageRange: PropTypes.array,
  handlePageRange: PropTypes.func.isRequired,
};

export default TableFooter;
