import 'assets/css/style.css';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import React from 'react';
import Routings from 'routes/Routings';

const App = () => {
  return (
    <React.Fragment>
      <ScrollToTop>
        <Routings />
      </ScrollToTop>
    </React.Fragment>
  );
};

export default App;
