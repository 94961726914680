import React from 'react';
const SvgInfoCircleFill = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#9CA3AF"
      fillRule="evenodd"
      d="M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0ZM6.702 6.657h2.122v4.52a.792.792 0 1 1-1.584 0V8.062h-.538a.702.702 0 0 1 0-1.405Zm.308-1.725c0-.124.022-.24.066-.348a.901.901 0 0 1 1.734.348.916.916 0 0 1-.071.354.917.917 0 0 1-.478.476.857.857 0 0 1-.346.07.967.967 0 0 1-.361-.07.92.92 0 0 1-.478-.476.955.955 0 0 1-.066-.354Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfoCircleFill;
