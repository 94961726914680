const ROUTES_ENUMS = {
  AUTH: {
    INDEX: '/?',
    LOGIN: '/login',
    SIGN_UP: '/sign-up/:invitationToken?',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:resetToken',
    VERIFICATION: '/verification/:verificationToken',
  },
  DASHBOARD: '/dashboard',
  INVITATION_LIST: '/invitations',
  PROJECTS: '/projects',
  ARCHIVED_PROJECTS: '/archived-projects',
  ARCHIVED_PROJECT_DETAILS: '/archived-projects/:projectId',
  VIEW_PROJECT: '/projects/:projectId',
  CREATE_PROJECT: '/projects/create',
  SUB_CONTRACTOR_VARIATION: '/sub-contractor/:projectId/variation',
  SETTING: {
    INDEX: '/setting',
    SUB_CONTRACTOR: {
      INDEX: '/setting/sub-contractor',
      CREATE: '/setting/sub-contractor/add',
      UPDATE: '/setting/sub-contractor/edit/:id',
    },
    MAIN_CONTRACTOR: {
      INDEX: '/setting/main-contractor',
      CREATE: '/setting/main-contractor/add',
      UPDATE: '/setting/main-contractor/edit/:id',
    },
    USERS: {
      INDEX: '/setting/web-users',
      CREATE: '/setting/web-users/add',
      UPDATE: '/setting/web-users/edit/:id',
    },
    APP_USERS: {
      INDEX: '/setting/app-users',
      CREATE: '/setting/app-users/add',
      UPDATE: '/setting/app-users/edit/:id',
    },
  },
  ROLE_MANAGEMENT: {
    ROLE: {
      INDEX: '/role-management/roles',
      CREATE: '/role-management/roles/add',
      UPDATE: '/role-management/roles/edit/:id',
    },
    SUB_ADMIN: {
      INDEX: '/role-management/roles/sub-admin',
      CREATE: '/role-management/roles/sub-admin/add',
      UPDATE: '/role-management/roles/sub-admin/edit/:id',
    },
  },
  ACCOUNT: '/account',
  VARIATION_DETAILS: '/projects/variation-detail/:projectId/:variationId/:revisionId',
  RFI_DETAILS: '/projects/rfi-detail/:projectId/:rfiId',
  PUBLIC_VARIATION_DETAILS: '/variation/:token/:variationId?/:revisionId?',
  DELETE_ACCOUNT: '/delete-account',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  404: '/404',
  401: '/401',
  LOGOUT: '/logout',
};
export default ROUTES_ENUMS;
