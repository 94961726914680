/* eslint-disable react/react-in-jsx-scope */
import { PageLoader } from 'components/core';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const MasterPage = () => {
  return (
    <div className="wrapper">
      <Header />
      <article>
        <Suspense fallback={<PageLoader />}>
          <Outlet />
        </Suspense>
      </article>
      <Footer />
    </div>
  );
};
export default MasterPage;
