import React from 'react';
const SvgTrashOutline = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="red"
    viewBox="0 0 18 18"
    {...props}
  >
    <g stroke="#DC2626" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.125}>
      <path d="M15.75 4.485a76.276 76.276 0 0 0-7.515-.375c-1.485 0-2.97.075-4.455.225l-1.53.15M6.375 3.728l.165-.983c.12-.712.21-1.245 1.478-1.245h1.964c1.268 0 1.366.563 1.478 1.252l.165.976M14.138 6.854l-.487 7.553c-.083 1.178-.15 2.093-2.243 2.093H6.593c-2.092 0-2.16-.915-2.242-2.093l-.488-7.553M7.748 12.375h2.497M7.125 9.375h3.75" />
    </g>
  </svg>
);
export default SvgTrashOutline;
