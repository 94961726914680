/* eslint-disable react/react-in-jsx-scope */
import { Button } from 'components/core';
import { PERMISSIONS } from 'constants/config.constants';
import { useAuth } from 'context/auth/AuthContext';
import PropTypes from 'prop-types';
import { FaUserGroup } from 'react-icons/fa6';
import { IoMdArchive } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import ROUTES_ENUMS from 'routes/url.enum';
import { IoIosNotificationsOutline } from 'react-icons/io';

const Header = ({ createProject }) => {
  const {
    user,
    hasNotFrontRole,
    checkDashboardActionPermission,
    checkPermission,
    isSubContractor,
  } = useAuth();
  const isAdminUser = hasNotFrontRole();
  const isSubContractorUser = isSubContractor();
  const isCanCreateProject = checkDashboardActionPermission({
    permissions: [PERMISSIONS.PROJECT.CREATE_PROJECT],
    isEditable: user?.isEditor,
  });
  const canViewProjectList = checkPermission([PERMISSIONS.PROJECT.VIEW_PROJECTS]);
  const canViewArchiveProjectList = checkPermission([PERMISSIONS.PROJECT.VIEW_ARCHIVE_PROJECTS]);
  const canViewAccount = checkPermission([PERMISSIONS.ACCOUNT]);
  const canManageSetting = checkPermission([PERMISSIONS.SETTINGS]);

  return (
    <header className="flex items-center justify-between ">
      <div className="w-[130px] h-[60px]">
        <NavLink to={ROUTES_ENUMS.DASHBOARD}>
          <img
            src={
              isAdminUser
                ? '/images/sinq-admin-logo.png'
                : user?.userCompany?.companyLogoFullPath || '/images/sinq-logo.png'
            }
            alt="Sinq Constructions"
            className="object-contain w-[130px] h-[60px]"
          />
        </NavLink>
      </div>
      <div className="menu flex h-[62px] items-center gap-[31px] bg-navy border-border border rounded-b-[12px] px-[42px] text-gray max-lg:mx-4 max-lg:items-center">
        <NavLink
          to="/dashboard"
          className={({ isActive }) => `max-lg:text-center ${isActive ? 'text-primary' : ''}`}
        >
          <span className="icon-dashboard pr-[10px]  max-lg:block"></span>
          <span className="max-lg:text-xs">Dashboard</span>
        </NavLink>

        {canViewProjectList && (
          <NavLink
            to={ROUTES_ENUMS.PROJECTS}
            className={({ isActive }) => `max-lg:text-center ${isActive ? 'text-primary' : ''}`}
          >
            <span className="icon-projects pr-[10px] max-lg:block"></span>
            <span className="max-lg:text-xs">Projects</span>
          </NavLink>
        )}
        {canViewArchiveProjectList && (
          <NavLink
            to={ROUTES_ENUMS.ARCHIVED_PROJECTS}
            className={({ isActive }) =>
              `max-lg:text-center max-lg:flex-col ${isActive ? 'text-primary' : ''} flex items-end max-lg:items-center mb-1`
            }
          >
            <IoMdArchive className="pr-[7px] text-3xl icon-projects m-0 p-0 max-lg:block" />
            <span className="max-lg:text-xs">Archived Projects</span>
          </NavLink>
        )}
        {canViewAccount && (
          <NavLink
            to={ROUTES_ENUMS.ACCOUNT}
            className={({ isActive }) => `max-lg:text-center ${isActive ? 'text-primary' : ''}`}
          >
            <span className="icon-user pr-[10px] max-lg:block"></span>
            <span className="max-lg:text-xs">Account</span>
          </NavLink>
        )}
        {canManageSetting && (
          <NavLink
            to={ROUTES_ENUMS.SETTING.INDEX}
            className={({ isActive }) => ` max-lg:text-center ${isActive ? 'text-primary' : ''}`}
          >
            <span className="icon-setting pr-[10px] max-lg:block"></span>
            <span className="max-lg:text-xs">Settings</span>
          </NavLink>
        )}
        {isAdminUser === true && (
          <NavLink
            to={ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.INDEX}
            className={({ isActive }) => `${isActive ? 'text-primary' : ''} flex items-center`}
          >
            <FaUserGroup className="pr-[10px]" size={30} />
            Role Management
          </NavLink>
        )}
      </div>
      <div className="flex items-center gap-4">
        <Button
          to={ROUTES_ENUMS.CREATE_PROJECT}
          classes={createProject && 'invisible'}
          disabled={!isCanCreateProject}
        >
          {' '}
          <span className="max-lg:hidden">New </span>{' '}
          <span className="ml-1 max-lg:capitalize"> project</span>{' '}
          <span className="icon-plus pl-[6px]"></span>
        </Button>
        {isSubContractorUser && (
          <Button
            to={ROUTES_ENUMS.INVITATION_LIST}
            className="!min-w-[30px] rounded-full p-2 bg-primary hover:bg-primary hover:text-white"
          >
            <IoIosNotificationsOutline size={25} />
          </Button>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  createProject: PropTypes.bool,
};

export default Header;
