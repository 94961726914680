/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';

const SearchInput = ({
  name,
  type = 'text',
  className,
  disabled = false,
  placeholder,
  ...rest
}) => {
  return (
    <React.Fragment>
      <input
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className={classNames(
          'text-sm font-poppins mt-3 py-[12px] pl-[16px] w-full border border-border focus:border-primary outline-0 bg-[#081028]',
          className
        )}
        {...rest}
      />
    </React.Fragment>
  );
};

export default SearchInput;
