/* eslint-disable react/prop-types */
import ConfirmDialog from 'components/ConfirmDialog';
import TableSearch from 'components/core/table-search/TableSearch';
import Table from 'components/core/table/Table';
import SelectBox from 'components/select-box/SelectBox';
import { TABLE } from 'constants/config.constants';
import { useListContext } from 'context/table-list/ListProvider';
import UseDeleteModal from 'hooks/useDeleteRow';
import useModal from 'hooks/useModalHook';
import React, { memo } from 'react';

const TableMaster = ({
  rows,
  columns,
  onEdit,
  onDelete,
  ViewModel,
  totalRecords = 0,
  ifFilter = false,
  filterOPtions = { options: [], value: null },
  filterOptionsLoading = false,
  filterOnchange,
  ...props
}) => {
  const listContext = useListContext();
  const { isOpen, id, openModal, closeModal } = useModal();
  const { isOpenDialog, openDialog, closeDialog, handleConfirm } = UseDeleteModal(onDelete);

  return (
    <React.Fragment>
      <div className="flex items-center justify-between">
        {ifFilter && (
          <SelectBox
            field="filter"
            hideErrorSpace
            value={filterOPtions.value}
            options={filterOPtions.options}
            controllerStyles={{
              maxWidth: '400px',
              height: '50px',
              backgroundColor: '#081028',
            }}
            isSearchable
            isClearable
            isLoading={filterOptionsLoading}
            onChange={filterOnchange}
          />
        )}
        <TableSearch handleSearch={listContext.handleSearch} />
      </div>
      <Table
        editable
        sr
        srTitle="#"
        loading={listContext.isLoadingList}
        rows={rows}
        columns={columns}
        rowsCount={totalRecords}
        currentPage={listContext.currentPage}
        pageSize={listContext.pageSize}
        isPaginate={listContext.isPaginate}
        customStyles={TABLE.LIST_CUSTOM_STYLE}
        dataStyles={TABLE.LIST_DATA_STYLE}
        nextButtonOnClick={listContext.handleNextPage}
        backButtonOnClick={listContext.handlePreviousPage}
        handlePageRange={listContext.handlePageSizeChange}
        onEdit={onEdit}
        onDelete={openDialog}
        onView={openModal}
        {...props}
      />
      {typeof ViewModel !== 'undefined' && isOpen && (
        <ViewModel id={id} isOpen={isOpen} handleCloseBtn={closeModal} />
      )}
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={closeDialog}
        onConfirm={handleConfirm}
        title="Confirm Delete"
        message="Are you sure you want to delete this user?"
        isLoading={false}
      />
    </React.Fragment>
  );
};

export default memo(TableMaster);
