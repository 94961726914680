/* eslint-disable react/prop-types */
import React from 'react';
import TableActions from 'components/core/table/components/TableActions';
import ComponentLoader from 'components/core/loader/ComponentLoader';

const TableBody = ({
  loading,
  columns,
  rows,
  editable,
  onView,
  onEdit,
  onDelete,
  dataStyles = {},
  hideDeleteButton,
  hideEditButton,
  showCopyButton,
  sr,
  pageSize = 10,
  currentPage,
  hideViewButton,
  // rowsCount,
}) => (
  <tbody>
    {loading && (
      <tr key="table-loading-tr" style={{ ...dataStyles }}>
        <td
          key="table-loading-td"
          style={{ ...dataStyles }}
          colSpan={columns.length + (sr ? 1 : 0) + (editable ? 1 : 0)}
        >
          <ComponentLoader className="h-[30vh]" />
        </td>
      </tr>
    )}
    {!loading && rows?.length === 0 ? (
      <tr key="table-zero-rows-tr" style={{ ...dataStyles }}>
        <td
          key="table-zero-rows-td"
          style={{ ...dataStyles, textAlign: 'center' }}
          colSpan={columns.length + (sr ? 1 : 0) + (editable ? 1 : 0)}
        >
          No Data.
        </td>
      </tr>
    ) : !loading ? (
      rows?.map((row, rowIndex) => {
        const { _id = '' } = row || {};
        return (
          <tr key={`${rowIndex}-${_id}`}>
            {sr && (
              <td style={{ ...dataStyles }}>
                {currentPage === 1 ? rowIndex + 1 : pageSize * (currentPage - 1) + (rowIndex + 1)}
              </td>
            )}
            {columns?.map(({ field, style = {} }, colIndex) => (
              <td key={`${colIndex}${field}${_id}`} style={{ ...dataStyles, ...style }}>
                {row?.[field] || '-'}
              </td>
            ))}
            {editable && (
              <TableActions
                _id={_id}
                onView={onView}
                onEdit={onEdit}
                onDelete={onDelete}
                hideDeleteButton={hideDeleteButton}
                hideEditButton={hideEditButton}
                hideViewButton={hideViewButton}
                showCopyButton={showCopyButton}
              />
            )}
          </tr>
        );
      })
    ) : (
      ''
    )}
  </tbody>
);

export default TableBody;
