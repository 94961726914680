import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'context/auth/AuthContext';
import ROUTES_ENUMS from 'routes/url.enum';
import { PageLoader } from 'components/core';

const GuestRoute = () => {
  const { isAuthenticate } = useAuth();

  if (isAuthenticate()) {
    return <Navigate to={ROUTES_ENUMS.DASHBOARD} replace />;
  }

  return (
    <article>
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </article>
  );
};

export default GuestRoute;
