/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { ListContext } from 'context/table-list/ListContext';
import useGetList from 'hooks/useGetList';
import useTableHandlers from 'hooks/useTableHandlers';

export const ListProvider = ({ children, value }) => {
  const {
    searchValue,
    currentPage,
    pageSize,
    handleSearch,
    handleNextPage,
    handlePreviousPage,
    handlePageSizeChange,
    filterValue,
    handleFilter,
  } = useTableHandlers();

  const { data, refetchList, isLoadingList } = useGetList({
    filter: filterValue,
    search: searchValue,
    page: currentPage,
    limit: pageSize,
    api_endpoint: value.api_endpoint,
    isPaginate: value.isPaginate ?? true, // Default to true if not provided
  });

  return (
    <ListContext.Provider
      value={{
        data,
        refetchList,
        isLoadingList,
        handlePreviousPage,
        handleNextPage,
        handlePageSizeChange,
        handleSearch,
        handleFilter,
        pageSize,
        currentPage,
        searchValue,
        isPaginate: value.isPaginate ?? true,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const useListContext = () => {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error('useRoleListContext must be used within a ListProvider');
  }
  return context;
};
