/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React from 'react';

const TextField = ({ field, type = 'text', form, className, ...rest }) => {
  return (
    <React.Fragment>
      <input
        {...field}
        type={type}
        className={classNames(
          'text-sm font-poppins mt-3 py-[12px] pl-[16px] w-full border border-border focus:border-primary outline-0 bg-[#081028]',
          className
        )}
        {...rest}
      />
      {form?.errors[field.name] && form?.touched[field.name] && (
        <ErrorMessage name={field.name} component="div" className="text-red-500 text-sm mt-1" />
      )}
    </React.Fragment>
  );
};

export default TextField;
