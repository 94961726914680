/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import classNames from 'classnames';

const SelectBox = ({
  field,
  isMulti,
  options,
  onChange,
  isClearable,
  isCreateable = false,
  className,
  menuListClass = '',
  controllerStyles = { minWidth: '250px' },
  ...rest
}) => {
  const [customOptions, setCustomOptions] = useState(options);

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCustomOptions([...customOptions, newOption]);
    onChange(isMulti ? [...field.value, newOption] : newOption);
  };

  useEffect(() => {
    if (options) {
      setCustomOptions(options);
    }
  }, [options]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '0.5rem',
      marginTop: '0px',
      // backgroundColor: '#081028',
      backgroundColor: 'transparent',
      borderColor: '#3B3B3B',
      boxShadow: 'none',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#0185ff',
      },
      ...controllerStyles,
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      color: '#FFFFFF',
      minHeight: 'unset !important',
      input: {
        minHeight: 'unset !important',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      '.select__single-value': {
        color: '#FFFFFF',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: '#FFFFFF',
    }),
    menu: (provided) => ({
      ...provided,
      border: '1px solid #0185ff',
      borderRadius: '8px',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      border: '1px solid #0185ff',
      borderRadius: '8px',
      ...menuListClass,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0b1739' : state.isFocused ? '#2C2C2C' : '#081028',
      color: 'white',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#0185ff',
      color: '#FFFFFF',
      borderRadius: '4px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: '#0185ff',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      margin: 0,
      padding: '1rem',
      backgroundColor: '#0b1739',
    }),
  };

  if (isCreateable) {
    const formatCreateLabel = (inputValue) => `Add - "${inputValue}"`;
    return (
      <CreatableSelect
        value={field.value}
        name={field.name}
        isMulti={isMulti || false}
        options={customOptions}
        onChange={onChange}
        formatCreateLabel={formatCreateLabel}
        onCreateOption={handleCreateOption}
        classNamePrefix="select"
        styles={customStyles}
        className={classNames('w-full text-white', className)}
        isClearable={isClearable}
        menuPlacement="auto"
        menuPosition="fixed"
        {...rest}
      />
    );
  }

  return (
    <ReactSelect
      value={field.value}
      name={field.name}
      isMulti={isMulti || false}
      options={customOptions}
      onChange={onChange}
      onCreateOption={handleCreateOption}
      classNamePrefix="select"
      styles={customStyles}
      className={classNames('w-full text-white', className)}
      isClearable={isClearable}
      menuPlacement="auto"
      menuPosition="fixed"
      {...rest}
    />
  );
};

export default SelectBox;
