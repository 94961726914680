import { formatDistanceToNow } from 'date-fns';

const GenerateRoute = (route, dynamic_data) => {
  let routeString = route;
  Object.keys(dynamic_data).forEach((element) => {
    routeString = routeString.replace(element, dynamic_data[element]);
  });
  return routeString;
};

const GetFormValues = (source, target) => {
  if (typeof source !== 'undefined') {
    let newTarget = { ...target };
    for (const key in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(key) && newTarget.hasOwnProperty(key)) {
        newTarget[key] = source[key];
      }
    }
    return newTarget;
  }
  return undefined;
};

const parseValue = (value) => {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return value;
  }
  return parseFloat((value?.toString() || '').replaceAll(',', '').replaceAll('£', '')) || 0;
};

const getTimeAgo = (dateString) => {
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
};

export { GenerateRoute, GetFormValues, parseValue, getTimeAgo };
