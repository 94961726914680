import React from 'react';

const PageLoader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 z-50">
      <div className="bg-navy p-5 rounded-lg shadow-lg">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
        <p className="mt-4 text-white text-center font-semibold">Loading...</p>
      </div>
    </div>
  );
};

export default PageLoader;
