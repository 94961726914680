/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { AuthContext, setUserToLocalStorage } from 'context/auth/AuthContext';
import { STORAGE } from 'storage/localStorageEnum';
import { getLocalItem, setLocalItem } from 'storage/localStorage';
import { DEFAULT_FRONT_ROLES, DEFAULT_ROLES } from 'constants/config.constants';

export const AuthProvider = ({ children }) => {
  const [user, addUser] = useState(getLocalItem(STORAGE.USER_DATA));

  const formatPermissions = (permissionsArr) => {
    const permissionMap = {};
    if (permissionsArr && permissionsArr.length > 0) {
      permissionsArr.forEach((permission) => {
        permissionMap[permission] = true;
      });
    }
    return permissionMap;
  };

  const [permissions, setPermissions] = useState(formatPermissions(user?.permissions || []));

  const setUser = (currentUser) => {
    setUserToLocalStorage({
      user_details: currentUser.userDet,
      token: currentUser.token,
    });
    addUser(currentUser?.userDet);
    if (currentUser?.userDet?.permissions && currentUser?.userDet?.permissions.length > 0) {
      setPermissions(formatPermissions(currentUser?.userDet?.permissions));
    }
  };

  const updateLocalUserDetails = (userDetails) => {
    setLocalItem(STORAGE.USER_DATA, userDetails);
    addUser(userDetails);
  };

  const isAuthenticate = () => !!user;

  const checkPermission = (requiredPermissions) => {
    if (!Array.isArray(requiredPermissions)) {
      throw new Error('Expected an array of permissions');
    }
    return requiredPermissions.every((permission) => permissions[permission]);
  };

  const checkOptionalPermission = (requiredPermissions) => {
    if (!Array.isArray(requiredPermissions)) {
      throw new Error('Expected an array of permissions');
    }
    return requiredPermissions.some((permission) => permissions[permission]);
  };

  const isMainContractor = () => {
    return user?.roles?.some((role) => role.name === DEFAULT_ROLES.MAIN_CONTRACTOR);
  };

  const isSubContractor = () => {
    return user?.roles?.some((role) => role.name === DEFAULT_ROLES.SUB_CONTRACTOR);
  };

  const isMainUser = () => {
    return isMainContractor() || isSubContractor();
  };

  const isSuperAdmin = () => {
    return user?.roles?.some((role) => role.name === DEFAULT_ROLES.SUPER_ADMIN);
  };

  const isSubUser = () => {
    return user?.roles?.some(
      (role) =>
        role.name === DEFAULT_FRONT_ROLES.PM ||
        role.name === DEFAULT_FRONT_ROLES.QS ||
        role.name === DEFAULT_FRONT_ROLES.SM ||
        role.name === DEFAULT_FRONT_ROLES.QS
    );
  };

  const hasNotFrontRole = () => {
    return !isMainContractor() && !isSubContractor() && !isSubUser();
  };

  const checkDashboardActionPermission = ({ permissions, isEditable = false }) => {
    const isNotFrontUser = hasNotFrontRole();
    if (isNotFrontUser) {
      return checkPermission(permissions);
    }
    return isEditable;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthenticate,
        checkPermission,
        checkOptionalPermission,
        isMainContractor,
        isSubContractor,
        isSuperAdmin,
        isSubUser,
        hasNotFrontRole,
        checkDashboardActionPermission,
        isMainUser,
        updateLocalUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
