import React from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'components/core';

// eslint-disable-next-line react/prop-types
const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message, isLoading }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div className="bg-[#0b1739] border border-gray-600 rounded-lg p-6 w-auto max-w-[450px]">
        <h2 className="text-lg font-semibold text-white mb-4">{title}</h2>
        <p className="text-gray-300 mb-4">{message}</p>
        <div className="flex justify-end">
          <Button
            type="button"
            disabled={isLoading}
            onClick={onClose}
            className="bg-[#0a1029] text-white rounded px-4 py-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={isLoading}
            isLoading={isLoading}
            className="bg-red-600 text-white rounded px-4 py-2"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmDialog;
