/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import styles from './tableSearch.module.css';
import { SearchInput } from '../index';

const TableSearch = (props) => {
  const { handleSearch } = props;

  return (
    <div className={styles.searchBoxContainer}>
      <SearchInput
        placeholder="Search..."
        className={styles.searchBox}
        name={'searchValue'}
        onChange={handleSearch}
      />
    </div>
  );
};

export default memo(TableSearch);
