import { useLocation, useParams } from 'react-router-dom';
import ROUTES_ENUMS from 'routes/url.enum';
import { GenerateRoute } from 'utils/CommonFun';

export default function useRoleManagement() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const currentTab = pathname.split('/').pop();

  const isRoleRoute = (pathname) => {
    const EDIT_ROUTE = GenerateRoute(ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.UPDATE, {
      ':id': id,
    });
    const rolesRoutes = [
      ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.INDEX,
      ROUTES_ENUMS.ROLE_MANAGEMENT.ROLE.CREATE,
      EDIT_ROUTE,
    ];

    return rolesRoutes.some((route) => pathname === route);
  };

  const isSubAdminRoute = (pathname) => {
    const EDIT_ROUTE = GenerateRoute(ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.UPDATE, {
      ':id': id,
    });

    const subAdminsRoutes = [
      ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.INDEX,
      ROUTES_ENUMS.ROLE_MANAGEMENT.SUB_ADMIN.CREATE,
      EDIT_ROUTE,
    ];

    return subAdminsRoutes.some((route) => pathname === route);
  };

  const getNavLinkClass = (isActive) => {
    return `hover:text-white hover:bg-primary border border-border rounded-lg px-2 py-2 flex ${isActive ? 'bg-primary' : 'bg-navy'}`;
  };

  return {
    currentTab,
    pathname,
    getNavLinkClass,
    isRoleRoute,
    isSubAdminRoute,
  };
}
